<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="Especialidades"
              rules="required"
            >
              <b-form-group label="Especialidades" label-for="v-especialidade">
                <v-select
                  v-model="form.especialidade"
                  :reduce="(comboEspecialidades) => comboEspecialidades.id"
                  label="descricao"
                  :options="comboEspecialidades"
                  :state="errors.length > 0 ? false : null"
                  data-cy="especialidade"
                />
                <small data-cy="v-especialidade" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="12">
            <validation-provider
              #default="{ errors }"
              name="solicitante"
              rules="required"
            >
              <b-form-group label="Solicitante" label-for="v-solicitante">
                <v-select
                  v-model="form.registro"
                  label="nome"
                  :filterable="false"
                  :options="comboRegistros"
                  :selected="form.registro"
                  @search="onSearchPaciente"
                  ><template slot="no-options">
                    Pesquisa registro por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-solicitante" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!--unidadeAtendimento -->
          <b-col cols="12" lg="12">
            <b-form-group
              label="Unidade Atendimento"
              label-for="v-unidadeAtendimento"
            >
              <v-select
                v-model="form.unidadeAtendimento"
                :reduce="
                  (comboUnidadeAtendimento) => comboUnidadeAtendimento.id
                "
                label="nome"
                :options="comboUnidadeAtendimento"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12">
            <b-form-group label="Observações" label-for="v-observacao">
              <b-form-textarea
                id="v-observacao"
                v-model="form.observacao"
                row="2"
                placeholder="Digite aqui suas observações"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Data Atendimento"
              rules="required"
            >
              <b-form-group label="Data Atendimento" label-for="v-dataAtendimento">
                <flat-pickr
                  v-model="form.dataAtendimento"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d'
                  }"
                />
                <small data-cy="v-dataAtendimento-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          
              <b-col cols="12" md="6"> 
              <validation-provider
                #default="{ errors }"
                name="Hora"
                rules="required"
              >
                <b-form-group label="Hora" label-for="v-hora">
                  <flat-pickr
                    v-model="form.horaInicial"
                    name="fieldDate"
                    :config="{
                      altInput: true,
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      time_24hr: true
                    }"
                  />
                  <small data-cy="v-hora-validate" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
              </b-col>
            </b-row>
          </b-col>

          <!-- submit and reset -->
           <ButtonsFormVue
          :disable-form="false"
          :loading-button="loading"
          :permission-insert="verificarPermissao('ROLE_PROFISSIONAL')"
          :permission-edit="verificarPermissao('ROLE_PROFISSIONAL')"
          @save="save"
          @edit="() => {}"
          @cancel="beforeCancelar"
        />
          
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BCard,
  BFormTextarea,
  BCalendar,
  BFormCheckbox,
  BBadge,
   BFormInput,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import mixinsGlobal from '@/mixins'
import mixinsAtendimento from '@/mixins/atendimento.js'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import _ from 'lodash'
import userServices from '@/api/access/userServices'

export default {
  name: 'FormAtendimentoLegadoProfissional',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BCalendar,
    ButtonsFormVue,
    flatPickr,
    BFormCheckbox,
    BBadge,
     BFormInput,
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal, mixinsAtendimento],
  computed: {
    ...mapState('combosModule', {
      // comboTiposAtendimento: (state) => state.comboTiposAtendimento
    })
  },
  async mounted() {
    this.loadComboUnidadeAtendimento()
    await this.loadComboEspecialidades()
  },
  methods: {
    loadComboEspecialidades(id) {
      this.comboEspecialidades = []
      this.$store
        .dispatch('combosModule/loadComboEspecialidadesProfissional')
        .then((response) => {
          this.comboEspecialidades = response.data
        })
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id ? objectSave.id : undefined,
        observacao: objectSave.observacao,
        situacao: objectSave.situacao,
        registro: objectSave.registro.id,
        unidadeAtendimento: objectSave.unidadeAtendimento,
        especialidade: objectSave.especialidade,
        avulso: objectSave.avulso,
        tipoAtendimento: objectSave.tipoAtendimento,
        profissional: objectSave.profissional.id,
        data: objectSave.dataAtendimento,
        horaInicial: objectSave.horaInicial,
        horaFinal: objectSave.horaInicial
      }
      return obj
    },
    save() {
      this.$refs.form.validate().then((success) => {
        const objectSave = this.prepareForSave(this.form)
       
        if (success) {
          this.loading = true
          this.$store
            .dispatch(`atendimentoProfissionalModule/inserirLegado`, objectSave)
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
                  `${response.response.data.errors[0].message}`
                )
                this.showForm = true
                this.loading = false
                return
              }
              if (response.error) {
                this.MensagemError('Falha ao Inserir', `${response.message}`)
                this.showForm = true
                this.loading = false
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cancelar()
            })
        }
      })
    },
    beforeCancelar () {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Deseja realmente sair do cadastro do atendimento? Os dados não salvos serão perdidos', {
          title: 'Confirmação',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Nao',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          this.boxTwo = value
          if (value) {
            this.cancelar()
          }
        })
    },
    cancelar() {
      this.loading = false
      this.$emit('cancelarLegado')
      this.cleanObjeto()
    },
    cleanObjeto() {
      this.form = {
        numeroAtendimento: '',
        observacao: '',
        situacao: '',
        profissional: '',
        registro: '',
        unidadeAtendimento: '',
        especialidade: '',
        avulso: true,
        tipoAtendimento: '',
        dataAtendimento: '',
        horaInicial: '',
        horaFinal: ''
      }
    },
    loadComboUnidadeAtendimento() {
      this.$store
        .dispatch('combosModule/loadComboUnidadeAtendimentoProfissional')
        .then((response) => {
          this.comboUnidadeAtendimento = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    onSearchPaciente(search, loading) {
      loading(true)
      this.searchPaciente(loading, search, this)
    },
    searchPaciente: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'atendimentoProfissionalModule/searchPaciente',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboRegistros = registrosEncontrados.data
      loading(false)
    }, 350),
  },
  data() {
    return {
      required,
      loading: false,
      titleForm: 'Cadastro de Atendimentos Legados',
      comboProfissionals: [],
      comboRegistros: [],
      form: {
        numeroAtendimento: '',
        observacao: '',
        situacao: '',
        profissional: '',
        registro: '',
        unidadeAtendimento: '',
        especialidade: '',
        avulso: true,
        tipoAtendimento: '',
        dataAtendimento: '',
        horaInicial: '',
        horaFinal: ''
      },
      comboUnidadeAtendimento: [],
      comboEspecialidades: [],
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
