<template>
  <div>
    <div v-if="!showFormLegado">
      <b-card v-if="user" no-body class="card-statistics">
        <b-card-header>
          <b-card-title>Resumo Atendimento Profissional</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0" />
        </b-card-header>
        <TableComponents
          v-if="!showForm"
          :columns="columns"
          :rows="listaAtendimentos"
          sort-name="nome"
          title-table="Atendimentos"
          module="atendimentoProfissionalModule"
          :show-btn-add="false"
          :show-action-delete="false"
          @edit="edit"
        >
          <slot slot="buttons">
            <b-button
              variant="success"
              class="ml-1"
              data-cy="inserirLegado"
              @click="inserirLegado"
            >
              Inserir Atendimento Legado
            </b-button>
          </slot>
        </TableComponents>
      </b-card>
    </div>
    <div v-if="showFormLegado">
      <AtendimentoLegadoForm @cancelarLegado="cancelarLegado" />
    </div>
  </div>
</template>

<script>
import TableComponents from '@/layouts/components/TableComponents.vue'
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BButton
} from 'bootstrap-vue'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import AtendimentoLegadoForm from './atendimentoLegado/AtendimentoLegadoForm.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    TableComponents,
    BButton,
    AtendimentoLegadoForm
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoProfissionalModule', {
      listaAtendimentos: (state) => state.listaAtendimentos
    })
  },
  data() {
    return {
      user: {},
      showForm: false,
      columns: [
        {
          label: 'Nº Atendimento',
          field: 'numeroAtendimento'
        },
        {
          label: 'Data Atendimento',
          field: 'dataAtendimento',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Hora',
          field: 'horaAgenda'
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: true
        },
        {
          label: 'Nome Solicitante',
          field: 'registro.nome',
          sortable: true
        },
        {
          label: 'Situação',
          field: 'situacao.descricao',
          tdClass: this.tdClassFunc,
          sortable: false
        },

        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      socket: null,
      messages: [],
      intervalId: null,
      showFormLegado: false
    }
  },
  mounted() {
    // this.connect()
    this.startAutoUpdate()
  },
  beforeUnmount() {
    // this.disconnectWebSocket();
  },
  beforeRouteLeave(to, from, next) {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    next()
  },
  methods: {
    inserirLegado() {
      this.showFormLegado = true
    },
    cancelarLegado() {
      this.showFormLegado = false
    },
    startAutoUpdate() {
      this.intervalId = setInterval(() => {
        this.loadTable()
      }, 300000)
      // 300000 Inicia o setInterval para atualizar a cada 5 minutos (180000 ms)
    },
    stopAutoUpdate() {
      // Limpa o intervalo quando o componente é desmontado
      clearInterval(this.intervalId)
    },
    async edit(data) {
      this.$router.push({
        path: `/modules/Attendance/attendance/AttendanceProfissional/editAtendimentoProfissional/${data.id}`
      })
    },
    async cancelar() {
      this.showForm = false
    },
    loadTable() {
      this.$store.dispatch('atendimentoModule/loadTable', this.pageConfig)
    },
    tdClassFunc(row) {
      if (row.situacao && row.situacao.descricao) {
        const status = row.situacao.descricao.toLowerCase()
        switch (status) {
          case 'aguardando':
            return 'text-primary'
          case 'em atendimento':
            return 'text-info'
          case 'finalizado':
            return 'text-success'
          case 'cancelado':
            return 'text-danger'
          case 'pendente':
            return 'text-warning'
          default:
            return ''
        }
      }
    }
    // unmounted() {
    //   this.disconnectWebSocket()
    // }
    // connect() {
    //   this.socket = new SockJS('http://teste.sysfactech.com.br:8081/ws')
    //   // this.socket = new WebSocket('ws://localhost:8080/some-websocket-endpoint');
    //   this.stompClient = Stomp.over(this.socket)

    //   this.stompClient.connect({}, (frame) => {
    //     console.log('Connected: ' + frame)

    //     // Assina o tópico para receber mensagens
    //     this.stompClient.subscribe('/topic/messages', (message) => {
    //       this.messages.push(JSON.parse(message.body))
    //     })
    //   })
    // },
    // sendMessage(message) {
    //   this.stompClient.send('/app/message', {}, JSON.stringify(message))
    // },
    // disconnectWebSocket() {
    //   if (this.socket) {
    //     this.socket.close()
    //     console.log('WebSocket disconnected')
    //   }
    // }
  }
}
</script>
